@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Kdam Thmor Pro', sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    --min-height: 840px;
    min-height: var(--min-height);
}

#__next {
    height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.bkg>.mask {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(255, 255, 255, 0));
}

.button-nice {
  border-radius: 9px 7px 5px 8px;
  box-shadow: 0 2px 0 black;
  filter: drop-shadow(4px 6px 0 rgba(0, 0, 0, 0.125));
  z-index: 99;
}

@keyframes airdrop-from-sky {
  0% {
    transform: translateY(-100%);
  }

  30% {
    transform: translateY(-5%);
  }

  50% {
    transform: translateY(-0%);
  }

  80% {
    transform: translateY(-5%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes flying {
  0% {
    transform: translateX(-5%) translateY(5%);
  }

  100% {
    transform: translateX(0%) translateY(0);
  }
}

@keyframes mask-up {
  0% {
    @apply opacity-0;
  }

  100% {
    @apply opacity-30 backdrop-blur-md
  }

}